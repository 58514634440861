import React from 'react';
import { User } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { logUserOut, userStateListener } from '../firebaseConfig';
import { createContext, useState, useEffect, ReactNode } from 'react';
import { LoginView } from '../views/LoginView/LoginView';

export enum AuthMode {
  EMAIL = 'email',
  GUEST = 'guest',
}

export const GUEST_MODE_KEY = 'golf_is_guest_mode';

interface Props {
  children?: ReactNode
}

/* eslint-disable @typescript-eslint/no-empty-function */
export const AuthContext = createContext({
  // 'User' comes from firebase auth-public.d.ts
  currentUser: {} as User | null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- not sure why the _ prefix isn't disabling TS checks by default
  setCurrentUser: (_user: User) => {},
  signOut: () => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setIsGuestMode: (_isGuestMode: boolean) => {},
  authMode: undefined as (AuthMode | undefined),
});

export function AuthProvider({ children }: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [isGuestModeStr, setIsGuestMode] = useState(localStorage.getItem(GUEST_MODE_KEY));
  const navigate = useNavigate();

  const isGuestMode = isGuestModeStr === 'true';

  useEffect(() => {
    const unsubscribe = userStateListener((user) => {
      setIsLoading(false);
      if (user) {
        setCurrentUser(user);
      }
    });
    return unsubscribe;
  }, [setCurrentUser]);

  if (isLoading) {
    return null;
  }

  const handleSetGuestMode = (isGM: boolean) => {
    localStorage.setItem(GUEST_MODE_KEY, String(isGM));
    setIsGuestMode(String(isGM));
  };

  // As soon as setting the current user to null,
  // the user will be redirected to the home page.
  function signOut() {
    handleSetGuestMode(false);
    logUserOut();
    setCurrentUser(null);
    navigate('/');
  }

  const value = {
    authMode: isGuestMode ? AuthMode.GUEST : AuthMode.EMAIL,
    setIsGuestMode: handleSetGuestMode,
    currentUser,
    setCurrentUser,
    signOut,
  };


  return (
    <AuthContext.Provider value={value}>
      {currentUser || isGuestMode ? children : <LoginView />}
    </AuthContext.Provider>
  );
}
