import React, { ChangeEventHandler } from 'react';
import { FormGroup } from '../';
import './TextInput.scss';

interface TextInputProps {
  id: string;
  value?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  label: string;
  placeholder?: string;
  type?: 'text' | 'password';
}

export function TextInput({
  id,
  value,
  onChange,
  label,
  placeholder,
  type = 'text',
}: TextInputProps) {
  return (
    <FormGroup
      label={label}
      inputId={id}
    >
      <input
        type={type}
        className="TextInput"
        value={value}
        onChange={onChange}
        id={id}
        name={id}
        placeholder={placeholder}
      />
    </FormGroup>
  );
}
