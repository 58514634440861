import React from 'react';
import './ScrollXRow.scss';

interface ScrollXRowProps {
  children?: React.ReactNode;
}

export function ScrollXRow({
  children,
}: ScrollXRowProps) {
  return (
    <div className="ScrollXRow">
      <div className="ScrollXRow__inner">
        {children}
      </div>
    </div>
  );
}
