import _ from 'lodash';
import { useMutation, useQuery } from '@tanstack/react-query';
import { GameType, PlayerType, ID } from './types';
import { useContext } from 'react';
import { AuthContext } from './context/AuthContext';
import { ServiceContext } from './context/ServiceContext';

/*
    GAMES
*/

export function useGameQuery(id?: ID) {
  const { gameService } = useServices();
  return useQuery(
    ['GAMES', id],
    () => gameService.getOneById(id ?? ''),
    { enabled: !!id },
  );
}
  
export function useGamesQuery() {
  const { gameService } = useServices();
  return useQuery(
    ['GAMES'],
    async () => {
      const games = await gameService.getMany();
      return _.orderBy(games, ['startDate'], ['desc']);
    },
  );
}

export function useGameMutation() {
  const { gameService } = useServices();
  return useMutation({
    mutationFn: (game: GameType) => gameService.upsertOne(game),
  });
}

/*
    PLAYERS
*/

export function usePlayersQuery() {
  const { playerService } = useServices();
  return useQuery(
    ['PLAYERS'],
    () => playerService.getMany(),
  );
}

export function useGamePlayers(game: GameType | undefined) {
  const allPlayersQuery = usePlayersQuery();
  if (!game || allPlayersQuery.isLoading || !allPlayersQuery.data) { return []; }
  const gamePlayerIds = game.playerStats.map(ps => ps.player.id);
  return allPlayersQuery.data.filter(player => gamePlayerIds.includes(player.id));
}

export function usePlayersMutation() {
  const { playerService } = useServices();
  return useMutation({
    mutationFn: async (players: PlayerType[]) => playerService.upsertMany(players),
  });
}

/*
  OTHER
*/

export function useAuth() {
  return useContext(AuthContext);
}

export function useServices() {
  return useContext(ServiceContext);
}
