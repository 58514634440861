import React from 'react';
import classNames from 'classnames';
import { CrownSimple } from '@phosphor-icons/react';

import './PlayerTag.scss';

export interface PlayerTagProps {
  type?: 'plain' | 'crown';
  label?: string;
}

export function PlayerTag({
  type = 'plain',
  label = undefined,
}: PlayerTagProps) {
  return (
    <div className={classNames(
      'PlayerTag',
      {
        'PlayerTag--crown': type === 'crown',
      },
    )}>
      {(type === 'crown') && (
        <CrownSimple weight="bold" />
      )}
      {label && (
        <span className="PlayerTag__label">
          {label}
        </span>
      )}
    </div>
  );
}
