import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';

import './LoginView.scss';
import { auth } from '../../firebaseConfig';
import { Button, TextInput } from '../../components';
import { useAuth } from '../../hooks';

export function LoginView() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { setIsGuestMode } = useAuth();

  function handleSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .catch((err) => {
        console.error(err);
        setError(err?.code ?? 'unknown error');
      });
  }

  function handleGuestMode() {
    setIsGuestMode(true);
  }

  return (
    <div className="LoginView">
      <form onSubmit={handleSubmit}>
        {error && <p className="LoginView__error">{error}</p>}
        <TextInput
          label="Email"
          type="text"
          id="login-emil"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <TextInput
          label="Password"
          placeholder="password"
          id="login-password-input"
          value={password}
          onChange={e => setPassword(e.target.value)}
          type="password"
        />
        <Button type="submit" variant="primary" size="large">
        LOGIN
        </Button>
      </form>
      <Button onClick={handleGuestMode} variant="primary" size="large">
        GUEST
      </Button>
    </div>
  );
}
