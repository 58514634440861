import { initializeApp } from 'firebase/app';
import { getAuth, signOut, onAuthStateChanged, NextOrObserver, User } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyB-Nqb93GuVdw-H4TYwHn4Oh6L4aTaZR8k',
  authDomain: 'golf-scoring-app-aaa38.firebaseapp.com',
  databaseURL: 'https://golf-scoring-app-aaa38-default-rtdb.firebaseio.com',
  projectId: 'golf-scoring-app-aaa38',
  storageBucket: 'golf-scoring-app-aaa38.appspot.com',
  messagingSenderId: '317420815635',
  appId: '1:317420815635:web:b52f1f27df89e85d6906e6',
  measurementId: 'G-7MCRLZBDHD',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);

export async function logUserOut() {
  await signOut(auth);
}

export function userStateListener(callback: NextOrObserver<User>) {
  return onAuthStateChanged(auth, callback);
}
