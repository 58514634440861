import React from 'react';
import { Link } from 'react-router-dom';
import './GameList.scss';
import { type GameType } from '../../types';
import { ScrollXRow, PlayerNameBlock } from '../';
import { lowestScoringPlayerIds } from '../../helpers';

interface GameListProps {
  data: GameType[];
}

export function GameList({
  data = [],
}: GameListProps) {
  return (
    <div className="GameList">
      {data.map((game: GameType) => (
        <Link
          key={game.id}
          className="GameList__row"
          to={`/game/${game.id}`}
        >
          <div className="GameList__header">
            <div className="GameList__date">
              {new Date(game.startDate).toDateString()}
            </div>
          </div>
          <ScrollXRow>
            <div className="GameList__players">
              {game.playerStats.map(playerStat => (
                <PlayerNameBlock
                  key={`${game.id}-${playerStat.player.id}`}
                  name={playerStat.player.name}
                  tags={lowestScoringPlayerIds(game).includes(playerStat.player.id) ? [
                    {
                      type: 'crown',
                      label: 'Leader',
                    },
                  ] : undefined}
                />
              ))}
            </div>
          </ScrollXRow>
        </Link>
      ))}
    </div>
  );
}
