import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';

import reportWebVitals from './reportWebVitals';

import { App } from './app';
import { type GameType } from './types';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const GameContext = React.createContext<GameType | null>(null);

root.render(
  <React.StrictMode>
    <GameContext.Provider value={null}>
      <App />
    </GameContext.Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
