import React from 'react';
import { createContext } from 'react';
import { Service } from '../services';
import { GameType, PlayerType } from '../types';
import { useAuth } from '../hooks';
import { useQuery } from '@tanstack/react-query';
import { initializeIndexedDB } from '../services/localService';
import { newFirebasePlayerService, newIDBPlayerService } from '../services/player';
import { newFirebaseGameService, newIDBGameService } from '../services/game';
import { AuthMode } from './AuthContext';

interface ServiceContext {
  gameService: Service<GameType>
  playerService: Service<PlayerType>
}

const globalFirebaseGameService = newFirebaseGameService();
const globalFirebasePlayerService = newFirebasePlayerService();

export const ServiceContext = createContext<ServiceContext>({
  gameService: globalFirebaseGameService,
  playerService: globalFirebasePlayerService,
});

// ServiceProvider provides whichever Service implementation is appropiate for the current AuthMode
export function ServiceProvider({ children }: { children: React.ReactNode }) {
  const auth = useAuth();
  const { data: services, isLoading } = useQuery(['services', auth.authMode], async () => {
    let gameService: Service<GameType>;
    let playerService: Service<PlayerType>;

    if (auth.authMode === AuthMode.GUEST) {
      console.log('initializing IDB services');
      const db = await initializeIndexedDB();
      gameService = newIDBGameService(db);
      playerService = newIDBPlayerService(db);
    } else {
      console.log('initializing Firebase services');
      gameService = globalFirebaseGameService;
      playerService = globalFirebasePlayerService;
    }

    return { gameService, playerService };

  }, {
    enabled: !!auth.authMode,

    // disable some refetch features that are typical for normal API requests
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
  });

  if (isLoading || !services) {
    return null;
  }

  return (
    <ServiceContext.Provider value={services}>
      {children}
    </ServiceContext.Provider>
  );

}
