import React from 'react';
import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';
import { List, Club } from '@phosphor-icons/react';
import './Header.scss';

import { SettingsMenu } from '../SettingsMenu/SettingsMenu';

interface HeaderProps {
  gameName: string;
}

const portalRoot = document.getElementById('portal-root') || document.body;

export function Header({
  gameName,
}: HeaderProps) {
  const [menuIsOpen, toggleMenuIsOpen] = React.useState(false);

  return (
    <>
      <div className="Header">
        <div className="Header__logo">
          <Link to="/">
            <Club size={52} />
          </Link>
        </div>
        <div className="Header__title">
          {gameName}
        </div>
        <div className="Header__menu">
          <button
            className="Header__menu-button"
            type="button"
            onClick={() => toggleMenuIsOpen(true)}
          >
            <List size={52} />
          </button>
        </div>
      </div>
      {createPortal(
        <SettingsMenu
          open={menuIsOpen}
          onClose={() => toggleMenuIsOpen(false)}
        />,
        portalRoot,
      )}
    </>
  );
}
