import React from 'react';
import { times } from 'lodash';
import classNames from 'classnames';

import './ScoreView.scss';
import { PlayerNameBlock } from '../PlayerNameBlock/PlayerNameBlock';
import { ScoreCell } from '../ScoreCell/ScoreCell';
import { Button } from '../Button/Button';
import { type GameType } from '../../types';
import { type PlayerTagProps } from '../PlayerTag/PlayerTag';
import {
  currentRound,
  currentDealer,
  playersSummedPoints,
  lowestScoringPlayerIds,
} from '../../helpers';
import { ArrowLeft, ArrowRight } from '@phosphor-icons/react';

interface ScoreViewProps {
  game: GameType;
  onScoreChange(playerIndex: number, roundIndex: number, score: number | null): void;
}

export function ScoreView({
  game,
  onScoreChange,
}: ScoreViewProps) {
  const [isCollapsed, updateIsCollapsed] = React.useState(false);
  const cssVars = {
    '--ScoreView-rows': game.playerStats.length,
    '--ScoreView-rounds': game.length,
  } as React.CSSProperties;

  const currRound = currentRound(game);

  function renderTags(playerID?: string) {
    const ids = lowestScoringPlayerIds(game);

    if (currentRound(game) === 0) {
      return undefined;
    }

    if (ids.length > 1 && ids.includes(playerID)) {
      return [{
        label: 'Tie',
        type: 'crown',
      }] as PlayerTagProps[];
    }

    if (ids.includes(playerID)) {
      return [{
        label: 'Leader',
        type: 'crown',
      }] as PlayerTagProps[];
    }

    return undefined;
  }

  return (
    <div
      className={classNames(
        'ScoreView',
        {
          'ScoreView--collapsed-sidebar': isCollapsed,
        },
      )}
      style={cssVars}
    >
      <div className="ScoreView__players">
        <div className="ScoreView__cell ScoreView__cell--header">
          {isCollapsed ? '' : 'Player'}
          <div className="ScoreView__collapse-button">
            <Button
              variant="transparent"
              size="small"
              onClick={() => updateIsCollapsed(!isCollapsed)}
            >
              {isCollapsed ? (
                <ArrowRight />
              ) : (
                <ArrowLeft />
              )}
            </Button>
          </div>
        </div>
        {game.playerStats.map(playerStat => (
          <div
            key={playerStat.player.id}
            className="ScoreView__cell"
          >
            <PlayerNameBlock
              name={isCollapsed ? playerStat.player.name.charAt(0) : playerStat.player.name}
              footingText={
                isCollapsed ?
                  playersSummedPoints(playerStat.player, game).toString() :
                  `Total: ${playersSummedPoints(playerStat.player, game)}`
              }
              tags={renderTags(playerStat.player.id)}
            />
          </div>
        ))}
      </div>

      <div className="ScoreView__scores">
        {times(game.length, round => (
          <div
            key={round}
            className={classNames(
              'ScoreView__cell',
              'ScoreView__cell--header',
              {
                'ScoreView__cell--current': currRound === round,
              },
            )}
          >
            <span>
              R{round + 1}
            </span>

            {/* TODO don't show if game is over */}
            {(currRound === round) && (
              <span>
                Deal: {currentDealer(game).name}
              </span>
            )}
          </div>
        ))}
        {game.playerStats.map((playerStat, playerIndex) => (
          times(game.length, (roundsIndex) => (
            <div
              key={roundsIndex}
              className={classNames(
                'ScoreView__cell',
                {
                  'ScoreView__cell--current': currRound === roundsIndex,
                },
              )}
            >
              <ScoreCell
                pointValue={playerStat.scores[roundsIndex]?.points}
                subtotalValue={2}
                onChange={score => onScoreChange(playerIndex, roundsIndex, score)}
                disabled={!!game.endDate}
              />
            </div>
          ))
        ))}
      </div>
    </div>
  );
}
