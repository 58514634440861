import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks';

export function RequireAuth({ children }: { children:JSX.Element }) {
  const { authMode } = useAuth();
  const location = useLocation();

  if (!authMode) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}
