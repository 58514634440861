
/*
  IndexedDB migrations. To change any of the schemas in the 'golf' db. Adding an entry here updates the db version
  and builds upon any existing database schemas that may exists in user's browsers.

*/

const migrations: ((db: IDBDatabase) => void)[] = [
  (db: IDBDatabase) => {
    db
      .createObjectStore('games', { keyPath: 'id', autoIncrement: true })
      .createIndex('data', 'data');

    db
      .createObjectStore('players', { keyPath: 'id', autoIncrement: true })
      .createIndex('data', 'data');  
    
  },
];

export default migrations;
