import {
  type GameType,
  type PlayerType,
} from './types';

export function playersSummedPoints(player: PlayerType, game: GameType) {
  const initialValue = 0;
  const currentPlayersPoints = game.playerStats?.find(stat => stat.player === player)?.scores;

  if (!currentPlayersPoints) {
    return 0;
  }

  return currentPlayersPoints.reduce((acc, curr) => {
    return acc + (curr.points || 0);
  }, initialValue);
}

export function lowestScoringPlayerIds(game: GameType) {
  const playerScores = game.playerStats.map(stat => {
    return {
      player: stat.player.id,
      points: playersSummedPoints(stat.player, game),
    };
  });

  const highestScore = playerScores.reduce((prev, curr) => {
    return prev.points < curr.points ? prev : curr;
  }).points;

  return playerScores.filter(ps => ps.points === highestScore).map(ps => ps.player);
}

export function currentRound(game: GameType) {
  // find the minimum length of the `scores` array across all players in the game
  // that array will dictate what the current round is, and which player's current turn it is
  const playersFirstNullValueIndexes = game.playerStats
    .map(ps => ps.scores.findIndex(s => s.points === null))
    .filter(idx => idx !== -1);
  return playersFirstNullValueIndexes?.length ? Math.min(...playersFirstNullValueIndexes) : game.length -1;
}

export function currentDealer(game: GameType) {
  const round = currentRound(game);
  const numPlayers = game.playerStats.length;
  const dealerIndex = (round) % numPlayers;

  return game.playerStats[dealerIndex].player;
}
