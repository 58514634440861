import classNames from 'classnames';
import React from 'react';
import './Button.scss';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'transparent';
  size?: 'small' | 'medium' | 'large';
  onClick?: () => void;
}

export function Button({
  variant = 'secondary',
  size = 'medium',
  onClick,
  children,
  ...buttonProps
}: ButtonProps) {
  return (
    <button
      {...buttonProps}
      onClick={onClick}
      className={classNames(
        'Button',
        { 'Button--primary': variant === 'primary' },
        { 'Button--secondary': variant === 'secondary' },
        { 'Button--transparent': variant === 'transparent' },
        { 'Button--small': size === 'small' },
        { 'Button--medium': size === 'medium' },
        { 'Button--large': size === 'large' },
      )}
    >
      <span className="Button__content">
        {children}
      </span>
    </button>
  );
}
