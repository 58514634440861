import React from 'react';
import { Trash } from '@phosphor-icons/react';
import './GameSetupPlayerRow.scss';
import { PlayerNameBlock } from '../PlayerNameBlock/PlayerNameBlock';

interface GameSetupPlayerRowProps {
  name: string;
  number: number;
  onDelete: () => void;
}

export function GameSetupPlayerRow({
  name,
  number,
  onDelete,
}: GameSetupPlayerRowProps) {
  return (
    <div className="GameSetupPlayerRow">
      <PlayerNameBlock
        headingText={`Player ${number}`}
        name={name}
      />
      <button
        className="GameSetupPlayerRow__button GameSetupPlayerRow__button--delete"
        onClick={onDelete}
      >
        <Trash weight="bold" />
      </button>
    </div>
  );
}
