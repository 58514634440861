import React from 'react';
import './Leaderboard.scss';
import { PlayerNameBlock } from '../PlayerNameBlock/PlayerNameBlock';
import { ScrollXRow } from '../ScrollXRow/ScrollXRow';
import { type PlayerType } from '../../types';

interface LeaderboardProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO any
  data?: any;
}

export function Leaderboard({
  data,
}: LeaderboardProps) {
  if (!data) {
    return (
      <div>
        Loading...
      </div>
    );
  }

  const leaderboardPlayers = data.players.filter((player: PlayerType) => (player?.wins || player?.losses));

  function calculateWinPercentage(player: PlayerType) {
    const { wins, losses } = player;

    if (!wins || !losses) return;

    const totalGames = wins + losses;

    return Math.round((wins / totalGames) * 100);
  }

  return (
    <ScrollXRow>
      <div className="Leaderboard">
        {leaderboardPlayers.length ? (
          leaderboardPlayers.map((player: PlayerType) => (
            <PlayerNameBlock
              key={player.id}
              name={player.name}
              details={(
                <>
                  {player.wins} wins <br />
                  {player.losses} losses <br />
                </>
              )}
              footingText={`${calculateWinPercentage(player)}% of games won`}
            />
          ))
        ) : (
          <p>No leaderboard players yet</p>
        )}
      </div>
    </ScrollXRow>
  );
}
