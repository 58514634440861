import { getDocs, collection, doc, FirestoreDataConverter, setDoc, addDoc, getDoc, query, where, documentId } from 'firebase/firestore';
import { ID } from '../types';
import { Service } from './index';
import { db } from '../firebaseConfig';

export type Collection = 'players' | 'players_test' | 'games' | 'games_test'

export default class DocumentService<Type extends { id?: ID }> implements Service<Type> {
  constructor(
    private collection: Collection,
    private converter: FirestoreDataConverter<Type>,
  ) {}

  upsertOne = (document: Type) => {
    return this.withErrorHandler(async () => {
      if (document.id) {
        const ref = this.makeDoc(document.id);
        await setDoc(ref, document);
        return document.id;
      } else {
        const ref = this.makeCollection();
        const added = await addDoc(ref, document);
        return added.id;
      }
    });
  };

  upsertMany = (documents: Type[]) => {
    return this.withErrorHandler(async () => {
      return Promise.all(documents.map(this.upsertOne));
    });
  };

  getOneById = (id: ID) => {
    return this.withErrorHandler(async () => {
      const ref = this.makeDoc(id);
      const snapshot = await getDoc(ref);
      if (!snapshot.exists()) {
        throw new Error(`${this.collection} document does not exist`);
      }
      return snapshot.data();
    });
  };

  getMany = (ids?: ID[]) => {
    return this.withErrorHandler(async () => {
      const ref = this.makeCollection();
      const constraints = ids ? [where(documentId(), 'in', ids)] : [];
      const snapshots = await getDocs(query(ref, ...constraints));
      const documents: Type[] = [];
      snapshots.forEach(s => {
        if (!s.exists()) {
          throw new Error('document does not exist');
        }
        documents.push(s.data());
      });
      return documents;
    });
  };

  private async withErrorHandler<R>(cb: () => Promise<R>) {
    try {
      return cb();
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  }

  private makeDoc(id: ID) {
    return doc(db, this.collection, id).withConverter<Type>(this.converter);
  }

  private makeCollection() {
    return collection(db, this.collection).withConverter<Type>(this.converter);
  }
}
