import React from 'react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { AuthProvider } from './context/AuthContext';
import { GameView } from './views/GameView/GameView';
import { AboutView } from './views/AboutView/AboutView';
import { DashboardView } from './views/DashboardView/DashboardView';
import { GameSetupView } from './views/GameSetupView/GameSetupView';
import { RequireAuth } from './components/RequireAuth/RequireAuth';
import { ServiceProvider } from './context/ServiceContext';

export function App() {
  return (
    <QueryClientProvider client={new QueryClient()}>
      <BrowserRouter>
        <AuthProvider>
          <ServiceProvider>
            <Routes>
              <Route index path="/" element={
                <RequireAuth>
                  <DashboardView />
                </RequireAuth>
              } />
              <Route path="/game-demo" element={
                <RequireAuth>
                  <GameView />
                </RequireAuth>
              } />
              <Route path="/game/:id" element={
                <RequireAuth>
                  <GameView />
                </RequireAuth>
              } />
              <Route path="/new-game" element={
                <RequireAuth>
                  <GameSetupView />
                </RequireAuth>
              } />
              <Route path="/about" element={<AboutView />} />
            </Routes>
          </ServiceProvider>
        </AuthProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}
