import migrations from '../migrations';
import { ID } from '../types';
import { Service } from './index';

const DB_NAME = `golf_app_${window.location.hostname}`;

// IndexedDB Service implementation
export default class LocalService<Type extends { id?: ID }> implements Service<Type> {

  constructor(private db: IDBDatabase, private storeName: string) {}

  async upsertOne(t: Type): Promise<string> {
    const req = await this.withTx('readwrite', (store) => {
      return store.put(t);
    });
    return req.result.toString();
  }
  
  async upsertMany(t: Type[]): Promise<string[]> {
    const req = await this.withTx('readwrite', (store) => {
      return t.map(item => {
        return store.put(item);
      });
    });
    return req.map(r => r.result.toString());
  }

  async getOneById(id: string): Promise<Type | undefined> {
    const req = await this.withTx('readonly', (store) => {
      return store.get(Number(id));
    });
    return req.result;
  }

  async getMany(ids?: string[] | undefined): Promise<Type[]> {
    if (ids) {
      const req = await this.withTx('readonly', (store) => {
        return ids.map(id => store.get(Number(id)));
      });
      return req.map(r => r.result);
    } else {
      const req = await this.withTx('readonly', (store) => {
        return store.getAll();
      });
      return req.result;
    }
  }

  private async withTx<Req extends IDBRequest | IDBRequest[]>(mode: IDBTransactionMode, cb: (store: IDBObjectStore) => Req): Promise<Req> {
    return await new Promise((resolve, reject) => {
      const tx = this.db.transaction([this.storeName], mode);
      const req = cb(tx.objectStore(this.storeName));
      tx.oncomplete = () => {
        resolve(req);
      };
  
      tx.onerror = () => {
        console.log(tx.error);
        reject(tx.error);
      };
    });
  }  
}


// initializes IDB - wraps callbacks with Promise which resolves on the onsuccess callback
export async function initializeIndexedDB() {
  return await new Promise<IDBDatabase>((resolve, reject) => {
    const DBOpenRequest = window.indexedDB.open(DB_NAME, migrations.length);
    
    DBOpenRequest.onerror = (event) => {
      console.error(event);
      reject(event);
    };
      
    DBOpenRequest.onsuccess = () => {
      console.log('IndexedDB connection opened successful');
      resolve(DBOpenRequest.result);
    };

    DBOpenRequest.onupgradeneeded = (event: IDBVersionChangeEvent) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const db: IDBDatabase = event.target.result;
      // apply each migration that has yet to be applied in the user's browser
      // https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API/Using_IndexedDB#creating_and_structuring_the_store
      for (let i = event.oldVersion; i < migrations.length; i++) {
        const migration = migrations[i];
        console.log(`running migration for version ${i + 1}`);
        migration(db);
      }
    };
  });

}
