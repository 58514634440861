import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowClockwise } from '@phosphor-icons/react';
import { DefaultLayout } from '../../layouts/DefaultLayout/DefaultLayout';
import { Button } from '../../components';

export function AboutView() {
  const navigate = useNavigate();

  return (
    <DefaultLayout
      title="About"
      onBackButtonClick={() => navigate(-1)}
    >
      <p className="mt-0">
        Version 1.0.0<br/>
        Last updated Sep 2, 2023
      </p>
      <h3>
        How to Play Golf
      </h3>
      <p>
        Golf (also known as Polish Polka, Polish Poker, Turtle, Hara Kiri and Crazy Nines) is a card game where players try to earn the lowest number of points (as in golf, the sport) over the course of nine deals (or &quot;holes&quot;).
      </p>
      <h4>
        The Deal
      </h4>
      <p>
      Two or three players use a standard 52-card deck. If played with four or more, a double-deck of 104 cards can be used.
      </p>
      <p>
        Each player is dealt six face-down cards from a shuffled deck. The remaining cards are placed face down to serve as the stock, from which the top card is taken and turned up to start the discard pile beside it. Players arrange their cards in two rows of three in front of them and turn any two of these cards face up. This arrangement is maintained throughout the game; players always have six cards in front of them.
      </p>
      <h4>
        The Play
      </h4>
      <p>
        The objective is for players to reduce the value of the cards in front of them by swapping them for lesser value cards. After the last round, the highest score loses the game and the lowest score wins the game (see scoring below).
      </p>
      <p>
        Beginning at the dealer&apos;s left, players take turns drawing single cards from either the stock or discard piles. The drawn card may either be swapped for one of that player&apos;s six cards or discarded. If the card is swapped for one of the face-down cards, the card swapped in remains face up. If the card drawn is discarded, the player can then choose to flip a card face up.
      </p>
      <p>
        The round ends when a player has six face-up cards (sometimes the other players are given one final turn following this), after which scoring happens as follows:
      </p>
      <ul>
        <li>
          Each Ace scores one point
        </li>
        <li>
          Each Two scores minus two points
        </li>
        <li>
          Each numeral card from 3 to 10 scores its face value
        </li>
        <li>
          Each Jack or Queen scores 10 points
        </li>
        <li>
          Each King scores zero points
        </li>
        <li>
          A pair of equal cards in the same column scores zero points for the column regardless of the rank of those cards
        </li>
      </ul>
      <p>
        During play, it is not legal for a player to pick up a card from the discard pile and return it to the discard pile without playing it. A card picked up from the discard pile must be swapped with one of the current player&apos;s cards.
      </p>
        A full game is typically nine &quot;holes&quot; (hands), after which the player with the lowest total score is designated the winner. A longer game can be played to eighteen holes.
      <section>
        <p>
          <Button
            type="button"
            onClick={() => window.location.reload()}
            variant="transparent"
            size="small"
          >
            Force Reload App
            <ArrowClockwise weight="bold" />
          </Button>
        </p>
      </section>
    </DefaultLayout>
  );
}
