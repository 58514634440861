import React from 'react';
import { Link } from 'react-router-dom';
import { Heart, Pizza, CaretLeft } from '@phosphor-icons/react';
import './DefaultLayout.scss';
import { Button } from '../../components';
import { AuthContext } from '../../context/AuthContext';

interface DefaultLayoutProps {
  title?: string;
  children?: React.ReactNode;
  onBackButtonClick?: () => void;
}

export function DefaultLayout({
  title,
  children,
  onBackButtonClick,
}: DefaultLayoutProps) {
  const { signOut } = React.useContext(AuthContext);

  return (
    <div className="DefaultLayout">
      <div className="DefaultLayout__header">
        {onBackButtonClick && (
          <Button
            type="button"
            onClick={onBackButtonClick}
            className="DefaultLayout__back"
            variant="transparent"
            size="small"
          >
            <CaretLeft weight="bold" />
            Back
          </Button>
        )}
        {title &&
          <div className="DefaultLayout__title size-h1">
            {title}
          </div>
        }
      </div>
      <div className="DefaultLayout__content">
        {children}
      </div>
      <div className="DefaultLayout__footer">
        <div className="DefaultLayout__tagline">
          Made with <Heart /> and <Pizza />
        </div>
        <div className="DefaultLayout__links">
          <button
            onClick={() => signOut()}
            type="button"
            className="DefaultLayout__link"
          >
            Log Out
          </button>
          <Link
            to="/about"
            className="DefaultLayout__link"
          >
            About
          </Link>
        </div>
      </div>
    </div>
  );
}
