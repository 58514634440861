import { DocumentSnapshot } from 'firebase/firestore';
import { PlayerType } from '../../types';
import DocumentService from '../documentService';
import LocalService from '../localService';

const COLLECTION_NAME = process.env.NODE_ENV === 'development' ? 'players_test' : 'players';

export function newPlayer(name: string) {
  return { name, wins: 0, losses: 0 };
}

export function newIDBPlayerService(db: IDBDatabase) {
  return new LocalService<PlayerType>(db, 'players');
}

export function newFirebasePlayerService() {
  return new DocumentService<PlayerType>(COLLECTION_NAME, {
    toFirestore: (p: PlayerType) => {
      return {
        name: p.name,
        wins: p.wins,
        losses: p.losses,
      };
    },
    fromFirestore: (snapshot: DocumentSnapshot) => {
      const playerData = snapshot.data() as PlayerType;
      return { id: snapshot.id, ...playerData };
    },
  });
}


