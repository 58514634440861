import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DefaultLayout } from '../../layouts/DefaultLayout/DefaultLayout';
import {
  GameSetupPlayerRow,
  TextInput,
  Button,
  FormGroup,
  ScrollXRow,
} from '../../components';
import { PlusCircle } from '@phosphor-icons/react';

import './GameSetupView.scss';
import { GameLength, PlayerType } from '../../types';
import { newPlayer } from '../../services/player';
import { usePlayersQuery, useServices } from '../../hooks';
import { newGame } from '../../services/game';

export function GameSetupView() {
  const navigate = useNavigate();
  const [newPlayerName, setNewPlayerName] = React.useState('');
  const [gamePlayers, setGamePlayers] = React.useState<PlayerType[]>([]);
  const [gameRoundCount, setGameRoundCount] = React.useState<GameLength>(18);
  // eslint-disable-next-line -- TODO use setCreateGame
  const [creatingGame, setCreatingGame] = React.useState(false);
  const playersQuery = usePlayersQuery();
  const existingPlayers = playersQuery.data ?? [];
  const { gameService, playerService } = useServices();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO any
  function handleSubmit(event: any) {
    event.preventDefault();
    handleAddPlayer(newPlayer(newPlayerName));
    setNewPlayerName('');
  }

  function handleAddPlayer(player: PlayerType) {
    setGamePlayers([
      ...gamePlayers,
      player,
    ]);
  }

  function handleDeletePlayer(name: string) {
    setGamePlayers(prev => prev.filter(p => p.name !== name));
  }

  function newNameIsValid() {
    return !gamePlayers.some(p => p.name === newPlayerName) && newPlayerName !== '';
  }

  async function startGame() {
    const playerIds = await playerService.upsertMany(gamePlayers);
    const players = await playerService.getMany(playerIds);
    const gameId = await gameService.upsertOne(newGame(gameRoundCount, players));
    navigate(`/game/${gameId}`);
  
  }

  return (
    <DefaultLayout
      title="New Game"
      onBackButtonClick={() => navigate(-1)}
    >
      <h3>
        {'Who\'s Playing'}
      </h3>
      {!!gamePlayers.length && (
        <div className="GameSetupView__players">
          {gamePlayers.map((player, index) => (
            <GameSetupPlayerRow
              key={player.name + index}
              name={player.name}
              number={index + 1}
              onDelete={() => handleDeletePlayer(player.name)}
            />
          ))}
        </div>
      )}
      {existingPlayers.length > 0 && (
        <div className="GameSetupView__recent-players">
          <FormGroup label="Quick Add">
            <ScrollXRow>
              <div className="GameSetupView__recent-player-buttons">
                {existingPlayers.map((p) => (
                  <Button
                    key={p.id}
                    className="GameSetupView__player-form__existing-player-button"
                    onClick={() => handleAddPlayer(p)}
                    disabled={gamePlayers.some(player => p.id === player.id)}
                    size="small"
                  >
                    {p.name}
                    <PlusCircle weight="bold" />
                  </Button>
                ))}
              </div>
            </ScrollXRow>
          </FormGroup>
        </div>
      )}
      <form onSubmit={event => handleSubmit(event)}>
        <div className="GameSetupView__player-form">
          <div className="GameSetupView__player-form__input">
            <TextInput
              id="new-player-name"
              label="New Player Name..."
              value={newPlayerName}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO any
              onChange={(event: any) => setNewPlayerName(event.target.value)}
              placeholder="type a name..."
            />
            <Button
              type="submit"
              disabled={!newNameIsValid()}
              size="medium"
              variant="primary"
            >
              Add
            </Button>
          </div>
          {(!newNameIsValid() && newPlayerName !== '') && (
            <p className="GameSetupView__player-form__error">
              Name already taken
            </p>
          )}
        </div>
      </form>
      <section>
        <h3>
          Game Length
        </h3>
        <div className="GameSetupView__holes">
          <Button
            size="small"
            variant={gameRoundCount === 18 ? 'primary' : 'secondary' }
            onClick={() => setGameRoundCount(18)}
          >
            18 Holes
          </Button>
          <Button
            size="small"
            variant={gameRoundCount === 9 ? 'primary' : 'secondary' }
            onClick={() => setGameRoundCount(9)}
          >
            9 Holes
          </Button>
        </div>
      </section>
      <section>
        <Button
          onClick={() => startGame()}
          disabled={gamePlayers.length < 2}
          variant="primary"
          size="large"
        >
          {creatingGame ? 'Starting Game...' : 'Start Game'}
        </Button>
      </section>
    </DefaultLayout>
  );
}
