import { GameLength, GameType, PlayerType } from '../../types';
import { DocumentSnapshot } from 'firebase/firestore';
import DocumentService from '../documentService';
import LocalService from '../localService';

const COLLECTION_NAME = process.env.NODE_ENV === 'development' ? 'games_test' : 'games';

export function newPlayerScores(length: GameLength) {
  return new Array(length).fill(undefined).map((_, i) => ({
    round: i + 1,
    points: null,
  }));
}

export function newGame(length: GameLength, players: PlayerType[]): GameType {
  return {
    length,
    startDate: Date.now(),
    playerStats: players.map(player => ({
      player,
      scores: newPlayerScores(length),
    })),
  };
}

export function newIDBGameService(db: IDBDatabase) {
  return new LocalService<GameType>(db, 'games');
}

export function newFirebaseGameService() {
  return new DocumentService<GameType>(COLLECTION_NAME, {
    toFirestore: g => g,
    fromFirestore: (snapshot: DocumentSnapshot) => {
      const gameData = snapshot.data() as GameType;
      return { id: snapshot.id, ...gameData };
    },
  });
}



