import React from 'react';
import classNames from 'classnames';

import './PlayerNameBlock.scss';
import { PlayerTag } from '../';
import type { PlayerTagProps } from '../PlayerTag/PlayerTag';

interface PlayerNameBlockProps {
  name: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO any
  details?: any;
  headingText?: string;
  footingText?: string;
  size?: 'default' | 'small';
  tags?: PlayerTagProps[];
}

export function PlayerNameBlock({
  name,
  details,
  headingText,
  footingText,
  size = 'default',
  tags = undefined,
}: PlayerNameBlockProps) {
  return (
    <div className={classNames(
      'PlayerNameBlock',
      {
        'PlayerNameBlock--small': size === 'small',
      },
    )}>
      {headingText && (
        <div className="PlayerNameBlock__heading-text">
          {headingText}
        </div>
      )}
      <div className="PlayerNameBlock__name">
        {name}
      </div>
      {details && (
        <div className="PlayerNameBlock__details">
          {details}
        </div>
      )}
      {footingText && (
        <div className="PlayerNameBlock__footing-text">
          {footingText}
        </div>
      )}
      {tags && (
        <div className="PlayerNameBlock__tags">
          {tags.map(tag => (
            <PlayerTag
              key={tag.label}
              {...tag}
            />
          ))}
        </div>
      )}
    </div>
  );
}
