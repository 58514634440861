import React from 'react';
import './FormGroup.scss';

interface FormGroupProps {
  inputId?: string;
  label: string;
  children: React.ReactNode;
}

export function FormGroup({
  inputId = undefined,
  label,
  children,
}: FormGroupProps) {
  return (
    <div className="FormGroup">
      {label && (
        <label
          className="FormGroup__label"
          htmlFor={inputId}
        >
          {label}
        </label>
      )}
      <div className="FormGroup__content">
        {children}
      </div>
    </div>
  );
}
