import React from 'react';
import './ScoreCell.scss';

interface ScoreCellProps {
  pointValue: number | null;
  subtotalValue?: number;
  onChange: (score: number | null) => void;
  disabled: boolean
}

export function ScoreCell({
  pointValue,
  subtotalValue,
  onChange,
  disabled,
}: ScoreCellProps) {

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    const isValid = val !== '' && !isNaN(Number(val));
    // if input value isn't valid number, update score value to null
    onChange(isValid ? Number(val) : null);
  };

  return (
    <div className="ScoreCell">
      <input
        type="number"
        value={Number.isInteger(pointValue) ? Number(pointValue) : ''}
        onChange={handleChange}
        className="ScoreCell__input"
        disabled={disabled}
      />
      <span className="ScoreCell__subtotal">
        {subtotalValue}
      </span>
    </div>
  );
}
