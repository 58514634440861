import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusCircle } from '@phosphor-icons/react';

import { DefaultLayout } from '../../layouts/DefaultLayout/DefaultLayout';
import { GameList, Leaderboard, Button } from '../../components';
import { useGamesQuery } from '../../hooks';
import { usePlayersQuery } from '../../hooks';

export function DashboardView() {
  const navigate = useNavigate();
  const gamesQuery = useGamesQuery();
  const playersQuery = usePlayersQuery();
  
  if (gamesQuery.isLoading || playersQuery.isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <DefaultLayout title="Golf">
      <Button
        onClick={() => navigate('/new-game')}
        size="large"
        variant="secondary"
      >
        New Game
        <PlusCircle weight="bold" />
      </Button>

      <section>
        <h3>
          Leaderboard
        </h3>
        <Leaderboard data={{ players: playersQuery.data ?? [] }} />
      </section>

      <section>
        <h3>
          Past Games
        </h3>
        <GameList data={gamesQuery?.data ?? []} />
      </section>
    </DefaultLayout>
  );
}
