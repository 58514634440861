import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './GameView.scss';

import { Button, ScoreView } from '../../components';
import { DefaultLayout } from '../../layouts/DefaultLayout/DefaultLayout';
import { GameType } from '../../types';
import { useGamePlayers, usePlayersMutation, useGameMutation, useGameQuery, useServices } from '../../hooks';
import { lowestScoringPlayerIds } from '../../helpers';

export function GameView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const gameQuery = useGameQuery(id);
  const gameState = useGameState(gameQuery.data);
  const gamePlayers = useGamePlayers(gameState.game);
  const playersMutation = usePlayersMutation();
  const gameMutation = useGameMutation();

  if (gameQuery.isLoading) {
    return <div>Loading...</div>;
  }

  if (!gameState.game) {
    return <div>Game Not Found</div>;
  }

  async function handleEndGameClick() {
    if (window.confirm('Ya sure you wanna end?') && gameState.game) {
      const winners = lowestScoringPlayerIds(gameState.game);
      const updatedPlayers = gamePlayers.map(p => {
        const playerWon = winners.includes(p.id);
        return { ...p, wins: playerWon ? Number(p.wins) + 1 : p.wins, losses: playerWon ? p.losses : Number(p.losses) + 1 };
      });
      await gameMutation.mutateAsync({ ...gameState.game, endDate: Date.now() });
      await playersMutation.mutateAsync(updatedPlayers);
      navigate('/');
    }
  }

  return (
    <DefaultLayout
      title="Game #"
      onBackButtonClick={() => navigate(-1)}
    >
      <div className="GameView">
        <ScoreView
          game={gameState.game}
          onScoreChange={gameState.updateScore}
        />
        {
          !gameState?.game.endDate && !gameQuery.isLoading && (
            <div style={{ marginTop: 40 }}>
              <Button
                onClick={handleEndGameClick}
                size="medium"
                variant="secondary"
                disabled={playersMutation.isLoading || gameMutation.isLoading}
              >
                End Game
              </Button>
            </div>
          )
        }
      </div>
    </DefaultLayout>
  );
}

// handles storing and updating game state
function useGameState(initialGame: GameType | undefined) {
  const [game, setGame] = useState(initialGame);
  useEffect(() => setGame(initialGame), [initialGame]);
  const { gameService } = useServices();

  const updateScore = (playerIndex: number, roundIndex: number, points: number) => {
    if (game) {
      const updatedGame = { ...game };
      updatedGame.playerStats = updatedGame.playerStats.map((ps, pIndex) => pIndex !== playerIndex ? ps : ({
        ...ps,
        scores: ps.scores.map((s, round) => round !== roundIndex ? s : { round, points }),
      }));
      setGame(updatedGame);

      // persist changes asynchronously
      gameService.upsertOne(updatedGame);
    }
  };

  return { game, updateScore };
}
